import '../../Styles/serviceCard.scss';

export default function ServiceCard({icon, title, children}) {
    return (
        <div className="serviceCard">
            <img src={icon} alt='icone' className="serviceCard-icon"/>
            <h3 className="serviceCard-title">{title}</h3>
            <div className="serviceCard-p">{children}</div>
        </div>
    )
}
import '../../Styles/services.scss';
import {Link} from "react-router-dom";
// Component imports
import Btn from '../../Components/Btn/btn';
import TitleService from '../../Components/TitleService/titleService';
import WebwiteServiceCard from '../../Components/WebsiteServiceCard/websiteServiceCard';
import GrafServiceCard from "../../Components/GrafServiceCard/grafServiceCard";
import SocialCard from "../../Components/SocialCard/socialCard";
import OtherServicesCar from "../../Components/OtherServicesCard/otherServicesCard";
//icons imports
import sites from '../../Assets/icons/sites.png';
import infography from '../../Assets/icons/infography.png';
import social from "../../Assets/icons/social.png";
import redaction from '../../Assets/icons/redaction.png';
//pics imports
import landing from '../../Assets/services/landing.jpg';
import vitrine from '../../Assets/services/vitrine.jpg';
import commerce from '../../Assets/services/commerce.jpg';
import crea_logo from '../../Assets/services/crea_logo.jpg';
import charte_graph from '../../Assets/services/charte_graph.jpg';
import publis from '../../Assets/services/publis.jpg';
import carteries from '../../Assets/services/carterie.jpg';
import brochures from '../../Assets/services/brochures.jpg';
import papeterie from '../../Assets/services/papeterie.jpg';
import evenementiel from '../../Assets/services/evenementiel.jpg';
import textile from '../../Assets/services/textile.jpg';
import affiches from '../../Assets/services/affiches.jpg';
import etiquettes from'../../Assets/services/etiquettes.jpg';
import restauration from '../../Assets/services/restauration.jpg';
import signaletique from '../../Assets/services/signaletique.jpg';
import lettrage from '../../Assets/services/lettrage.jpg';
import goodies from '../../Assets/services/goodies.jpg';
import packaging from '../../Assets/services/packaging.jpg';
import catalogue from '../../Assets/services/catalogue.jpg';







export default function Services(){
    const descLanding =["Site internet ne comportant qu’une seule page","Ce type de site internet est la solution" +
    " idéale si vous n’avez  que peu de contenu à présenter.","Une landing page va présenter une brève description de votre société, les services proposés et vos coordonnées.","Cette solution permet aux tout petits budgets d’avoir une présence sur le net."]
    const listLanding= ["Accompagnement pour le choix du nom de domaine (le prix du nom de domaine peut varier en" +
    " fonction des choix effectués)","Création d’adresses mail personnalisées", "Sécurisation complète du site", "Intégration de votre identité visuelle (charte graphique + logo)", "Ajout des mentions légales", "Optimisation du référencement naturel (SEO)", "Un site adapté à tous les supports (responsive)"]
    const descVitrine=["Nous proposons des sites conçus spécialement pour vous.", "Chaque détail est pensé selon vos" +
    " besoins et votre identité.", "Un site internet est votre carte de visite digitale, il est le reflet de votre image de marque et de votre savoir-faire.\n" +
    "Il est l’outil indispensable pour présenter votre offre et guider vos visiteurs."]
    const listVitrine=["Accompagnement pour le choix du nom de domaine (le prix du nom de domaine peut varier en" +
    " fonction des choix effectués)", "Création d’adresses mail personnalisées", "Sécurisation complète du site", "Intégration de votre identité visuelle (charte graphique + logo)", "Elaboration de 3 à 5 pages (par ex : Accueil, Services, A propos, Contact)", "Ajout des mentions légales", "Optimisation du référencement naturel (SEO)", "Un site adapté à tous les supports (responsive)"]
    const descCommerce = ["La solution idéale pour développer votre activité sur le web.", "Vous bénéficierez d’une" +
    " solution professionnelle et dynamique. Vos clients pourront passer commander et payer directement sur votre" +
    " site, via un système de paiement 100% sécurisé.", "Tout sera entièrement personnalisable, des délais de livraison à l’envoi des mails, en passant par les modes de livraison (click & collect, point relais...)"]
    const listCommerce =["Accompagnement pour le choix du nom de domaine (le prix du nom de domaine peut varier en" +
    " fonction des choix effectués)", "Création d’adresses mail personnalisées", "Sécurisation complète du site", "Création du site grâce à Woocommerce (extension WordPress)", "Paramétrage de la boutique (taxes, modes de livraison, modes de paiement, mails automatiques...)", "Création de 5 fiches produits (le reste est à la charge du client)", "Mise en place des Conditions Générales de Vente (suivant texte fournis par le client) (CGV)", "Ajout des mentions légales", "Optimisation du référencement naturel (SEO)", "Un site adapté à tous les supports (responsive)"]
    const infografList= [
        {title:"Logo",desc:["Des logos au designs originaux, qui capturent l’essence de votre marque et vous" +
            " distingue de la concurrence", "Donnez vie à votre identité visuelle"], img:crea_logo},
        {title:"Charte graphique",desc:["L’identité visuelle de votre société. Allant du choix des couleurs à la" +
            " typographie", "Donnez une direction cohérente à votre communication"], img:charte_graph},
        {title:"Publications",desc:["Des publications, animées ou non, pour vos réseaux ou votre blog", "Brillez sur le net en toute occasion"], img:publis},
        {title:"Carterie",desc:["Des créations uniques pour vos cartes de visite, remerciement... et plus encore", "Exprimez avec élégance et personnalité"], img:carteries},
        {title:"Brochures",desc:["Des flyers et brochures aux designs percutants, qui captent l’attention et mettent" +
            " en valeur votre message", "Sublimez vos produits et services"], img:brochures},
        {title:"Papèterie",desc:["Toutes vos fournitures de bureau (enveloppes, tampons, fardes ...)", "Ajouter votre image à toutes vos correspondances"], img:papeterie},
        {title:"Evénementiel",desc:["Des solutions adaptées à tous vous événements (roll-up, oriflammes, stands ...)", "Faites de chaque événement un succès"], img:evenementiel},
        {title:"Textile",desc:["Habillez vos équipes aux couleurs de votre société avec nostenues personnalisées", "Votre image de marque où que vous alliez"], img:textile},
        {title:"Affiches",desc:["Pour l’intérieur ou l’extérieur, des affiches percutantes qui captent l’attention", "Faites passer vos messages avec panache"], img:affiches },
        {title:"Etiquettes",desc:["Ajoutez une touche distinctive à vos produits ou emballages avec nos étiquettes" +
            " personnalisés", "Emballez avec créativité"], img:etiquettes },
        {title:"Restauration",desc:["Ajoutez de l’élégance à votre restaurant avec des cartes, sets de table," +
            " sous-verres... personnalisés", "Offrez une expérience unique à vos clients"], img:restauration},
        {title:"Signalétique",desc:["Personnalisez votre société à votre image grâce à nos devantures, enseignes..." +
            " à vos couleurs", "Accueillez vos clients avec brio"], img:signaletique},
        {title:"Lettrage",desc:["Emportez votre image partout avec notre service de personnalisation de véhicule", "Brillez où que vous alliez"], img:lettrage},
        {title:"Goodies",desc:["Offrez des cadeaux uniques, à votre image, avec notre gamme de goodies", "Laissez un souvenir durable"], img:goodies},
        {title:"Packaging",desc:["Emballez vos produits avec des designs uniques, qui charmeront vos clients", "Captivez vos clients au premier regard"], img:packaging},
        {title:"Notre catalogue", desc:["Prochainement vous pourrez découvrir notre catalogue en cliquant ici"], img:catalogue}]



    return (
        <div className="servicesPage">
            <div className="servicesTitleBtn">
                <h1 className="titleServices">NOS <span>SERVICES</span></h1>
                <div className="contactUsService">
                    <Btn to="/contact" text="NOUS CONTACTER"/>
                </div>
            </div>

            {/*websites services*/}
            <div className="service" id="sites">
                <div className="sitewebTitle" >
                    <TitleService icon={sites} title="Création de sites Web sur mesure"  />
                </div>
                <div className="divCards" >
                    <div className="sitewebCards">
                        <WebwiteServiceCard pic={landing}
                                            title="Landing Page"
                                            desc = {descLanding}
                                            hoverPrice="A partir de 700€"
                                            hoverList= {listLanding} />
                    </div>
                    <div className="sitewebCards">
                        <WebwiteServiceCard pic={vitrine}
                                            title="Site vitrine"
                                            desc={descVitrine}
                                            hoverPrice="A partir de 1000€"
                                            hoverList={listVitrine} />
                    </div>
                    <div className="sitewebCards">
                        <WebwiteServiceCard pic={commerce}
                                            title="Site e-commerce"
                                            desc={descCommerce}
                                            hoverPrice="A partir de 1800€"
                                            hoverList={listCommerce}/>
                    </div>
                </div>

            </div>

            {/*infography services*/}
            <div className="service" id="infograf">
                <div className="sitewebTitle" >
                    <TitleService icon={infography} title="Infographie Print et Digitale"  />
                </div>
                <div className="infografCard">
                    {infografList.map((service, index) => {
                        if (service.title === "Notre catalogue") {
                            return (
                                <Link to="#" className="cardInfo" id="catalogue" >
                                    <GrafServiceCard
                                        img={service.img}
                                        title={service.title}
                                        desc={service.desc}
                                    />
                                </Link>
                            );
                        } else {
                            return (
                                <div className="cardInfo">
                                    <GrafServiceCard
                                        key={index}
                                        img={service.img}
                                        title={service.title}
                                        desc={service.desc}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>

            {/*social services*/}
            <div className="service" id="social">
                <div className="sitewebTitle" >
                    <TitleService icon={social} title="Réseaux sociaux" />
                </div>
                <div className="socialText"  >
                    <p>Nous réalisons avec vous un <strong>planning éditorial</strong>, en définissant les différents types de publications, ainsi que les thèmes que vous souhaitez aborder, puis nous les <strong>publierons</strong> pour vous <strong>sur les différents réseaux sociaux</strong> (Facebook, Instagram, LinkedIn).</p>
                    <p>Nous créerons pour vous des <strong>visuels impactants et 100% personnalisés</strong>, afin de capter l’intérêt de votre audiance et de transmettre les valeurs de votre société.</p>
                    <p>Nous pouvons également <strong>dynamiser votre présence en ligne grâce à des jeux concours</strong> lors d’événements particuliers (vacances d’été, Noël, anniversaire ...).</p>
                </div>
                <div className="socialPrice">
                    <SocialCard/>
                </div>
            </div>

            {/*redaction services*/}
            <div className="service" id="redac">
                <div className="sitewebTitle" >
                    <TitleService icon={redaction} title="Rédaction" />
                </div>
                <div className="socialText" >
                    <p>Nous vous offrons une <strong>palette complète de solutions</strong> pour répondre à vos besoins en  communication.</p>
                    <p>Que ce soit pour votre présence en ligne, grâce à la <strong>rédaction d’articles</strong> (pour votre blog, site, ou réseau social..) ou à la <strong>rédaction de newsletters</strong> ; ou au travers de vos <strong>correspondances traditionnelles</strong> (commerciales, administratives ...), nous sommes là pour donner vie à vos idées et communiquer efficacement votre message.</p>
                </div>
            </div>

            {/*other services*/}
            <div className="otherService">
                <h3>Nos autres services digitaux</h3>
                <OtherServicesCar/>
            </div>

            {/*contact btn*/}
            <div className="contactUsDownService">
                <Btn to="/contact" text="NOUS CONTACTER"/>
            </div>
        </div>
    )
}
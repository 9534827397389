import '../../Styles/form.scss';
import {Link} from "react-router-dom";
import {
    useRef,
    useState
} from "react";
import emailjs from '@emailjs/browser';


export default function Form(){
    const [name, setName] = useState('');
    const [societe, setSociete] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [services, setServices] = useState('site vitrine');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState('');
    const form = useRef();


    /**
     * set service state when user selects a service
     * @param e
     */
    const handleServiceChange = (e) => {
        setServices(e.target.value);
    };

    /** reset all form fields
     *
     */
    const resetForm = () => {
        setName('');
        setSociete('');
        setEmail('');
        setPhone('');
        setServices('site vitrine'); // Valeur par défaut
        setMessage('');
    };


    // Soumission du formulaire
    const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');  // Réinitialise l'erreur avant la soumission

    //     const localUrl = 'http://localhost:4000/sendEmail';
    //     const prodUrl = 'https://grendy-communication.fr/sendEmail'
    //
    //     const formDataObj = {
    //         email,
    //         name,
    //         societe,
    //         phone,
    //         services,
    //         message,
    //     };
    //
    // try {
    //         const response = await axios.post('http://localhost:4000/sendEmail', formDataObj, {
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     });
    //         console.log('Email envoyé avec succès:',formDataObj);
    //     setIsSubmitted(true);
    // } catch (error) {
    //     console.error('Erreur lors de l\'envoi de l\'email:', error);
    //     setError('Une erreur s\'est produite lors de l\'envoi du formulaire.');
    // }

        // try {
        //     const response = await axios.post('http://localhost:4000/send_email.php', formDataObj, {
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     });
        //
        //     console.log('Email envoyé avec succès:', response.data);
        //     setIsSubmitted(true);
        // } catch (error) {
        //     console.error('Erreur lors de l\'envoi de l\'email:', error);
        //     setError('Une erreur s\'est produite lors de l\'envoi du formulaire.');
        // }

        emailjs
            .sendForm("service_hfgiqie","template_bdsd0cf", form.current, {
                publicKey: 'VIMMcbud2-pCW6KQ8',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setIsSubmitted(true);
                    resetForm();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setError("Une erreur s'est produite lors de l'envoi du formulaire.");
                },
            );
};

    return (
        <form className="contactForm" ref={form} onSubmit={handleSubmit}>
            <div className="name_and_society">
                <div className="name_society_input">
                    <label htmlFor="name">Nom / Prénom *</label>
                    <input type="text" id="name" name="name" value={name} onChange={(e)=> setName(e.target.value)} required/>
                </div>
                <div className="name_society_input">
                    <label htmlFor="societe">Société</label>
                    <input type="text" id="societe" name="societe" value={societe} onChange={(e)=> setSociete(e.target.value)}/>
                </div>
            </div>

            <div className="name_and_society">
                <div className="name_society_input">
                    <label htmlFor="email">Adresse mail *</label>
                    <input type="email" id="email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
                </div>
                <div className="name_society_input">
                    <label htmlFor="phone">Téléphone</label>
                    <input type="tel" id="phone" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                </div>
            </div>

            <div className="select_services_input">
                <label htmlFor="service">Services</label>
                <select name="services" id="services" value={services} onChange={handleServiceChange}>
                    <option value="site vitrine">Site vitrine</option>
                    <option value="landing page">Landing page</option>
                    <option value="site e-commerce">Site e-commerce</option>
                    <option value="infographie">Infographie</option>
                    <option value="reseau social">Réseaux sociaux</option>
                    <option value="redaction">Rédaction</option>
                    <option value="autre">Autre service</option>
                </select>
            </div>

            <div className="message_csv_input_area">
                <div className="message_input_area">
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" cols="50" rows="15" value={message} onChange={(e)=>setMessage(e.target.value)} required ></textarea>
                </div>
                <div className="csv_input_area">
                    <input type="checkbox" required/>
                    <label htmlFor="okCSV">J'accepte la <Link to="/politiqueConfidentialite" className="politique_confid">politique de confidentialité </Link></label>
                </div>

            </div>

            <div className="sendForm">
                {/*<Btn type="submit" text="Envoyer"/>*/}
                <button type="submit">Envoyer</button>
            </div>

            {isSubmitted && (
                <>
                    <div className="message-submit">
                        <p className="message-submit-text">Formulaire envoyé avec succès !</p>
                        <div className="closing-cross" onClick={() => setIsSubmitted(!isSubmitted)}>X</div>
                    </div>
                </>)
            }
            {error &&
                <p className="message-submit">{error}</p>}
        </form>
    )
}
import '../../Styles/accroche.scss';
import logo from '../../Assets/logo.png';

export default function Accroche() {
    return(
        <div className="accroche-container">
            <img src={logo} alt="Logo Grendy Communication" className="accroche-logo"/>
            <section className="accroche-text">
                <h1><span>Grendy</span> Communication, votre agence Web dans le Grand-Est</h1>
                <aside className="grendy-presentation">
                    <p>Que vous souhaitiez afficher votre présence sur internet à l’aide d’un <strong>site vitrine</strong>, vendre vos produits grâce à un <strong>site d’e-commerce</strong> , ou encore faire vivre votre société sur les <strong>réseaux sociaux</strong>, <strong><span>Grendy </span>Communication </strong>est présent à vos côtés.</p>

                    <p>Notre service <strong>infographie Print et Digital</strong> est également à votre disposition pour votre communication visuelle.</p>

                    <p>Création de logo et charte graphique, conception de cartes de visite et flyers, ou encore flocage de véhicule ou signalétique ... <strong>Pour chacun de vos besoins nous avons la solution qu’il vous faut</strong>.</p>
                </aside>
            </section>
        </div>
    )
}
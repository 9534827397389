
export default function PolitiqueConfidentialite(){
    return(
        <div>
            <div className="mentions-legales">
                <h1>Politique de confidentialité</h1>

                {/*bloc identification entreprise*/}
                <div className="mentions-legales-editeur">
                    <h2> Identification de l’entreprise :</h2>
                    <div>
                        <p><strong>Nom de l'agence :</strong> Michel Lallemand Sandy (Entreprise Individuelle), sous le
                            nom
                            commercial Grendy Communication.</p>
                        <p><strong>Forme juridique :</strong> Auto-entreprise (Entreprise Individuelle)</p>
                        <p><strong>Capital social :</strong> 1€</p>
                        <p><strong>Siège social :</strong> 8 rue du Vichoux F-54920 Morfontaine</p>
                        <p><strong>Numéro de Siret :</strong> 947 537 023 00014</p>
                        <p><strong>Téléphone :</strong> +33 (0) 3 55 05 49 11</p>
                        <p><strong>Email :</strong><a
                            href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
                        <p><strong>Directeur de la publication :</strong> Lallemand Sandy</p>
                    </div>
                </div>

                {/*bloc but de la politique*/}
                <div className="mentions-legales-editeur">
                    <h2>Le but de cette politique de confidentialité :</h2>
                    <p>Le but de cette politique de confidentialité est d’informer les utilisateurs de notre site des
                        données personnelles que nous recueillons, ainsi que des information suivantes, le cas
                        échéant:
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> Les données personnelles que nous recueillons</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> L’utilisation des données recueillies
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> Qui a accès aux données recueillies</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> Les droit des utilisateurs du site</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>e.</span> La politique de cookies du site
                    </p>
                    <p>Cette politique de confidentialité s’applique en complément des conditions générales
                        d’utilisation de notre site.</p>
                </div>

                {/*bloc loi applicable*/}
                <div className="mentions-legales-editeur">
                    <h2>Lois applicables :</h2>
                    <p>Conformément au Règlement général sur la protection des données (RGPD), cette politique
                        de confidentialité est conforme aux normes suivantes.</p>
                    <p>Les données à caractère personnel doivent être :</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> traitées de manière licite, loyale et transparente au regard de la personne concernée
                        (licéité, loyauté, transparence);
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> collectées pour des finalités déterminées, explicites et légitimes, et ne pas être
                        traitées ultérieurement d’une manière incompatible avec ces finalités; le traitement
                        ultérieur à des fins archivistiques dans l’intérêt public, à des fins de recherche
                        scientifique ou historique ou à des fins statistiques n’est pas considéré,
                        conformément à l’article 89, paragraphe 1, comme incompatible avec les finalités
                        initiales (limitation des finalités);

                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités
                        pour lesquelles elles sont traitées (minimisation des données);</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être
                        prises pour que les données à caractère personnel qui sont inexactes, eu égard aux
                        finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder
                        (exactitude)?
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>e.</span>conservées sous une forme permettant l’identification des personnes concernées
                        pendant une durée n’excédant pas celle nécessaire au regard des finalités pour
                        lesquelles elles sont traitées; les données à caractère personnel peuvent être
                        conservées pour des durées plus longues dans la mesure où elles seront traitées
                        exclusivement à des fins archivistiques dans l’intérêt public, à des fins de recherche
                        scientifique ou historique ou à des fins statistiques conformément à l’article 89,
                        paragraphe 1, pour autant que soient mises en oeuvre les mesures techniques et
                        organisationnelles appropriées requises par le règlement afin de garantir les droits et
                        libertés de la personne concernée (limitation de la conservation);
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>f.</span>traitées de façon à garantir une sécurité appropriée des données à caractère
                        personnel, y compris la protection contre le traitement non autorisé ou illicite et
                        contre la perte, la destruction ou les dégâts d’origine accidentelle, à l’aide des
                        mesures techniques ou organisationnelles appropriées (intégrité et confidentialité);
                    </p>
                    <p>Le traitement n’est licite que si, et dans la mesure où, au moins une de ces conditions
                        suivantes est remplie :
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> la personne concernée a consenti au traitement de ses données à caractère
                        personnel pour une ou plusieurs finalités spécifiques;</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> le traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée
                        est partie ou à l’exécution de mesures précontractuelles prises à la demande de
                        celle-ci;
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> le traitement est nécessaire au respect d’une obligation légale à laquelle le
                        responsable du traitement est soumis;
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne
                        concernée ou d’une autre personne physique;
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>e.</span> le traitement est nécessaire à l’exécution d’une mission d’intérêt public ou relevant
                        de l’exercice de l’autorité publique dont est investi le responsable du traitement;
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>f.</span> le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le
                        responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou
                        les libertés et droits fondamentaux de la personne concernée qui exigent une
                        protection des données à caractère personnel, notamment lorsque la personne
                        concernée est un enfant.
                    </p>

                </div>

                {/*bloc consentement*/}
                <div className="mentions-legales-editeur">
                    <h2>Consentement :</h2>
                    <p>Les utilisateurs conviennent qu’en utilisant notre site, ils acceptent :

                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> les conditions énoncées dans la présente politique de confidentialité et
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> la collecte, l’utilisation et la conservation des données énumérées dans la présente
                        politique.
                    </p>
                </div>

                {/*bloc données personnelles*/}
                <div className="mentions-legales-editeur">
                    <h2>Données personnelles que nous collectons :</h2>
                    <h2>Données collectées automatiquement :</h2>
                    <p>Nous ne collectons aucune donnée automatiquement sur notre site.</p>
                    <h2>Données recueillies de façon non automatique :</h2>
                    <p>Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines
                        fonctions sur notre site :
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> Prénom et Nom</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> Nom de la société</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> Adresse mail</p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> N° de téléphone</p>
                    <p>Ces données peuvent être recueillies au moyen des méthodes suivantes :
                        formulaire de contact.
                    </p>
                    <p>Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif
                        énoncé dans cette politique de confidentialité.
                        Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.</p>
                </div>

                {/*bloc partage des données*/}
                <div className="mentions-legales-editeur">
                    <h2>Avec qui nous partageons les données personnelles :</h2>
                    <h3>Employés</h3>
                    <p>Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont ce
                        membre a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente
                        politique.
                    </p>
                    <h3>Tierces parties</h3>
                    <p>Nous pouvons partager les données utilisateur avec les tiers suivants :</p>
                    <p>Nous pouvons partager les données utilisateur avec des tiers aux fins suivantes :</p>
                    <p>Les tiers ne seront pas en mesure d’accéder aux données utilisateurs au-delà de ce qui est
                        raisonnablement nécessaire pour atteindre l’objectif donné.
                    </p>
                    <h3>Autres divulgations</h3>
                    <p>Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans
                        les cas suivants :
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> si la loi l’exige
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> si elle est requise pour toute procédure judiciaire
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> pour prouver ou protéger nos droits légaux
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous
                        cherchons à vendre la société
                    </p>
                    <p>Si vous consultez les hyperliens de notre site vers un autre site, veuillez noter que nous ne
                        sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de
                        confidentialité.
                    </p>
                </div>

                {/*bloc durée protection des données*/}
                <div className="mentions-legales-editeur">
                    <h2>Combien de temps nous stockons les données personnelles :</h2>
                    <p>Les données personnelles sont conservées aussi longtemps que nécessaire pour les fins
                        pour lesquelles elles ont été collectées, ou pour se conformer à nos obligations légales. Une
                        fois cette durée écoulée, les données seront supprimées de manière sécurisée.
                    </p>
                </div>

                {/*bloc protection des données*/}
                <div className="mentions-legales-editeur">
                    <h2>Comment nous protégeons vos données personnelles :</h2>
                    <p>Lorsque vous vous connectez à notre site, vos données sont transmises via une connexion
                        sécurisées (HTTPS), garantissant qu’elles sont protégées contre toute interception ou
                        manipulation.
                    </p>
                    <p>Bien que nous prenions toutes les précautions raisonnables pour sécuriser les données de
                        nos utilisateurs et garantir leur protection, il subsiste toujours un certain risque. L’internet
                        dans son ensemble peut parfois présenter des vulnérabilités, et il nous est donc impossible
                        d’assurer une sécurité absolue des données des utilisateurs au-delà de ce qui est
                        raisonnablement réalisable.
                    </p>
                    <p>En cas de violation de la sécurité affectant vos renseignements personnels, nous vous en
                        informerons conformément aux obligations légales et prendrons les mesures nécessaires
                        pour en limiter les risques.
                    </p>
                </div>

                {/*bloc protection des données*/}
                <div className="mentions-legales-editeur">
                    <h2>Mineurs</h2>
                    <p>Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des
                        mineurs aux fins de la collecte des données. Les mineurs doivent avoir le consentement
                        d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.
                    </p>
                </div>

                {/*bloc droits utilisateurs*/}
                <div className="mentions-legales-editeur">
                    <h2>Vos droit en tant qu’utilisateur :</h2>
                    <p>En vertu du RGPD, les utilisateurs ont les droits suivants sur leurs données en tant que
                        personnes concernées :
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>a.</span> droit d’accès
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>b.</span> droit de rectification
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>c.</span> droit à l’effacement
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>d.</span> droit de restreindre le traitement
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>e.</span> droit à la portabilité des données
                    </p>
                    <p><span style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>f.</span> droit d’objection
                    </p>
                    <p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (article 12-23) du
                        RGPD.
                    </p>
                    <p>Comment modifier, supprimer ou contester les données recueillies :</p>
                    <p>Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou
                        d’une autre, veuillez nous en faire part par mail à l’adresse suivante :
                        <a href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a>
                    </p>

                </div>

                {/*bloc modifications*/}
                <div className="mentions-legales-editeur">
                    <h2>Modifications :</h2>
                    <p>Cette politique de confidentialité peut être modifiée de temps à autre afin de maintenir la
                        conformité avec la loi et de tenir compte de tout changement à notre processus de collecte
                        de données. Nous recommandons à nos utilisateurs de vérifier notre politique de
                        confidentialité de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au
                        besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à
                        cette politique.

                    </p>
                </div>

                {/*bloc contact*/}
                <div className="mentions-legales-editeur">
                    <h2>Contact</h2>
                    <p>Pour toute question ou information, vous pouvez nous contacter à l’adresse suivante :</p>
                    <p style={{marginBottom: '0'}}>Grendy Communication</p>
                    <p>8 rue du Vichoux F-54920 Morfontaine</p>
                    <p><a href="tel: +33355054911">+33 (0) 3 55 05 49 11</a></p>
                    <p><a href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
                </div>

                <div className="mentions-legales-editeur">
                    <p>Date d'entrée en vigueur de la présente politique : le 2 décembre 2024</p>
                </div>
            </div>
        </div>
    )
}
import '../../Styles/stepCard.scss';


export default function StepCard({num, title, desc}){
    return (
        <div className="stepCard">
            <h3>{num} - {title}</h3>
            <p className="stepCardDesc">{desc}</p>
        </div>
    )
}
import '../../Styles/socialCard.scss';

export default function SocialCard(){
    return(
        <div className="socialCard">
            <div className="socialCardTitle">
                <h2>Notre offre multiplateformes</h2>
                <p>(Facebook, Instagram, LinkedIn)</p>
            </div>

            <p className="socialCardPrice">A partir de 200€ / mois</p>

            <ul className="socialCardList">
                <li>Création d'un planning éditorial</li>
                <li>Création et diffusion de 4 publications / mois</li>
                <li>Réponses et modération de vos clients</li>
            </ul>

        </div>
    )
}
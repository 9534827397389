import '../../Styles/otherCard.scss';

export default function OtherCard({img, title, desc}){
    return(
        <div className="otherCard">
            <img src={img} alt="icone service"/>
            <div className="textCard">
                <h3>{title}</h3>
                <p>{desc}</p>
            </div>
        </div>
    )
}
import '../../Styles/realisations.scss';
import CustomerCard from "../../Components/CustomerCard/customerCard";
import Btn from '../../Components/Btn/btn';
import petitsPlats from "../../Assets/realisations/petitsPlats.jpg";
import sorrente from "../../Assets/realisations/sorrente.jpg";
import cnvdf from "../../Assets/realisations/cnvdf.jpg";
import fisheye from "../../Assets/realisations/fisheye.jpg";
import baba from "../../Assets/realisations/baba.jpg";
import ohmyfood from "../../Assets/realisations/ohmyfood.jpg";
import sev from"../../Assets/realisations/sev.jpg";
import booki from "../../Assets/realisations/booki.jpg";
import sandy from "../../Assets/realisations/sandy.jpg";


export default function Realisations(){
    const customers = [
        {title:'Les petits plats', img:petitsPlats, desc:"Réalisation d’un site de recettes de cuisine", elements:[]},
        {title:'Sorrente Pizza', img:sorrente, desc:"Création de divers supports visuels dont :", elements:["un" +
            " flyer", "des étiquettes", "des affiches", "un lettrage de véhicule"]},
        {title:'CNVDF', img:cnvdf, desc:"Création de supports visuels dont :", elements:["un roll up", "des" +
            " maillots personnalisés", "un flyer"]},
        {title:'Fisheye', img:fisheye, desc:"Réalisation d’un site pour une plateforme de photographes freelances", elements:[]},
        {title:"Les Babatisseries de Jo", img:baba, desc:"Création d'un logo", elements:[]},
        {title:"Ohmyfood", img:ohmyfood, desc:"Réalisation d’un site qui répertorie les menus de restaurants" +
                " gastronomiques", elements:[] },
        {title:"Séverine & Mike", img:sev, desc:"Créations pour un mariage :", elements:["un faire-part", "un livre" +
            " d'or", "des enveloppes personnalisées"]},
        {title:"Booki", img:booki, desc:"Réalisation d’une plateforme permettant de trouver des logements et" +
                " activités dans la ville de son choix", elements:[]},
        {title:"Sandy & Grégory", img:sandy, desc:"Création d’un faire- part de mariage, avec coupon réponse et" +
                " enveloppe personnalisée", elements:[]}
    ]


    return (
        <div className="realisations">
            <h1 className="titleRealisation">NOS <span>REALISATIONS</span></h1>

            <div className="realisationsCards">
                {customers.map((customer, index)=> {
                    return(
                        <div className="customerCards">
                            <CustomerCard
                                key={index}
                                img={customer.img}
                                title={customer.title}
                                desc={customer.desc}
                                elements={customer.elements}
                            />
                        </div>
                    )

                })}
            </div>

            {/*contact btn*/}
            <div className="contactUsDownRealisation">
                <Btn to="/contact" text="NOUS CONTACTER"/>
            </div>
        </div>
    )

}
import '../../Styles/contact.scss'
import Form from "../../Components/Form/form";
import Btn from "../../Components/Btn/btn";

export default function Contact(){
    return (
        <div className="contact">
            <h1 className="titleContact">CONTACT</h1>

            <Form/>

            {/*<div className="sendForm">*/}
            {/*    <Btn to="/" text="ENVOYER"/>*/}
            {/*</div>*/}
        </div>
    )
}
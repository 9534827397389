import '../../Styles/websiteServiceCard.scss';

export default function WebwiteServiceCard({pic, title, desc, hoverList, hoverPrice}){
    return (
        <div className="websiteCard">
            <img src={pic} alt="website picture"/>
            <h2>{title}</h2>
            <div className="siteDesc">
                {desc.map((description, index) => (
                    <p key={index}>{description}</p>
                ))}
            </div>

            <div className="hiddenText">
                <h4>{hoverPrice}</h4>
                <ul>
                    {hoverList.map((item, index) => (
                        <li key={index}>{item}<br/><br/></li>

                    ))}
                </ul>
            </div>

        </div>
    )
}
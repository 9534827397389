import '../../Styles/etapes.scss';
import StepCard from '../../Components/StepCard/stepCard';

export default function Etapes(){
    return(
        <div className="nosServices">
            <h2>LES <span>ETAPES </span>DU PROJET</h2>

            <div className="steps">
                <StepCard num="01" title="Prise de Contact" desc="Suite à votre demande, nous allons nous entretenir par téléphone afin de définir vos attentes et vos besoins."/>
                <StepCard num="02" title="Présentation du projet" desc="Nous vous présentons le devis qui correspond à votre demande, ainsi que le cahier des charges de votre projet, que nous allons valider ensemble."/>
                <StepCard num="03" title="Validation" desc="Nous vous présentons la maquette du projet, que nous validerons ensemble (des modifications sont naturellement possibles avant validation définitive)"/>
                <StepCard num="04" title="Conception" desc="Une fois la maquette validée, nous passons à la conception de votre projet."/>
                <StepCard num="05" title="Livraison" desc="Une fois le projet terminé, nous passons à la remise des livrables, conformément au calendrier défini dans le cahier des charges."/>
                <StepCard num="06" title="Suivi" desc="Nous assurons l’hébergement et la maintenance de votre site web."/>
            </div>
        </div>
    )
}
import '../../Styles/home.css';
import Dynamic from '../../Components/DymamicBg/dynamicBg';
import Accroche from '../../Components/Accroche/accroche';
import Services from '../../Components/NosServices/nosServices';
import Etapes from '../../Components/Etapes/etapes';
import Btn from '../../Components/Btn/btn';

export default function Accueil() {
    return (
        <div className="home">
            <div className="homeDynamique">
                <Dynamic />
            </div>

            <div className="homeAccroche">
                <Accroche />
            </div>

            <div className="homeServices">
                <Services />
            </div>

            <div className="homeEtapes">
                <Etapes />
            </div>

            <div className="contactUs">
                <Btn to="/contact" text="DEMANDER UN DEVIS"/>
            </div>
        </div>
    )
}
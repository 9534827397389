import '../../Styles/dynamicBg.scss';
import dynamic from '../../Assets/dynamic.mp4';
import logo from '../../Assets/logo.png'


export default function Dynamic() {
    return (
        <div className="dynamicSection">
            <div>
                <video autoPlay muted loop id="dynamicVideo">
                    <source src={dynamic} type="video/mp4"/>
                </video>
                <div className="agenceName">
                    <img src={logo} alt="Logo Grendy Communication" className="mainLogo"/>
                    <h2 className="grendyDynamic"><span>Grendy </span>Communication</h2>
                    <div className="agenceDynamic">AGENCE WEB & COMMUNICATION</div>
                </div>
            </div>
        </div>
    )
}
import '../../Styles/btn.scss';
import {Link} from 'react-router-dom'

export default function Btn({text, to, type}) {
    return(
        <Link to ={to} type={type} className="contactBtn">
            <h4 className="BtnLink">{text}</h4>
        </Link>

    )
}
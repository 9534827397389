import '../../Styles/realisation.scss';
import logo from '../../Assets/logo.png'


export default function Realisation(){
    return (
        <div className="realisation">
            <h1>Cette page est actuellement en construction</h1>
            <p>Revenez prochainement pour découvrir toutes nos réalisations</p>
            <img src={logo} alt="logo Grendy Communication"/>
            <p className="cross">X</p>
        </div>
    )
}
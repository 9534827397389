import '../../Styles/headser.scss'
import logo from '../../Assets/logo.png'
import {Link} from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import {useState} from "react";


/**
 * header with navbar
 */
export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="header">

            {/*div with logo anf company name */}
            <div className="logoAndName">
                <img src={logo} alt="Logo Grendy communication" className="logoNav"/>
                <Link to="/" className="companyName">
                    <div className="grendyNav"><span>Grendy </span>Communication</div>
                    <br/>
                    <div className="agenceNav">AGENCE WEB & COMMUNICATION</div>
                </Link>
            </div>

             {/* Burger icon for small screens */}
            <div className="burgerIcon" onClick={toggleMenu}>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
                <div className={menuOpen ? "bar open" : "bar"}></div>
            </div>

            {/* div for nav bar (4 links) */}
            <div className="navBar">
                <NavLink to="/" className={({isActive}) => (isActive ? 'active-link-nav' : 'link-nav')}>Accueil</NavLink>
                <NavLink to="/Services" className={({isActive}) => (isActive ? 'active-link-nav' : 'link-nav')}>Services</NavLink>
                <NavLink to="/Realisations" className={({isActive}) => (isActive ? 'active-link-nav' : 'link-nav')}>Nos réalisations</NavLink>
                <NavLink to="/Contact" className={({isActive}) => (isActive ? 'active-link-nav' : 'link-nav')}>Contact</NavLink>
            </div>

            {/* phone number*/ }
            <div className="phoneNumber">
                <a href="tel: +33355054911">+33 (0) 3 55 05 49 11</a>
            </div>
        </div>
    )
}
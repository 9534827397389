import '../../Styles/mentionLegales.scss';

export default function MentionsLegales() {
    return (
        <div className="mentions-legales">
            <h1>Mentions Légales</h1>

            {/*bloc editeur*/}
            <div className="mentions-legales-editeur">
                <h2>Editeur du site :</h2>
                <p>Le présent site internet, accessible à l’adresse www.grendy-communication.fr , est édité par
                    Grendy Communication
                </p>
                <div>
                    <p><strong>Nom de l'agence :</strong> Michel Lallemand Sandy (Entreprise Individuelle), sous le nom
                        commercial Grendy Communication.</p>
                    <p><strong>Forme juridique :</strong> Auto-entreprise (Entreprise Individuelle)</p>
                    <p><strong>Capital social :</strong> 1€</p>
                    <p><strong>Siège social :</strong> 8 rue du Vichoux F-54920 Morfontaine</p>
                    <p><strong>Numéro de Siret :</strong> 947 537 023 00014</p>
                    <p><strong>Téléphone :</strong> +33 (0) 3 55 05 49 11</p>
                    <p><strong>Email :</strong><a href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
                    <p><strong>Directeur de la publication :</strong> Lallemand Sandy</p>
                </div>
            </div>

            {/*bloc hébergeur*/}
            <div className="mentions-legales-editeur">
                <h2>Hébergeur du site :</h2>
                <p>Le site est hébergé par : </p>
                <p><strong>Nom de l'hébergeur :</strong> OVH</p>
                <p><strong>Adresse :</strong> 2 rue Kellermann - 59100 Roubaix - France</p>
                <p><strong>Site web :</strong> <a href="https://www.ovhcloud.com" target="_blank">www.ovhcloud.com</a></p>
            </div>

            {/*bloc propriété intellectuelle*/}
            <div className="mentions-legales-editeur">
                <h2>Propriété intellectuelle</h2>
                <p>
                    L’ensemble du contenu présent sur ce site (textes, images, graphismes, logo, icônes, etc.)
                    est protégé par les lois en vigueur sur la propriété intellectuelle.
                </p>
                <p>
                    Toute reproduction, distribution, modification, adaptation ou publication, même partielle, de
                    ces éléments est strictement interdite sans autorisation écrite préalable de Grendy
                    Communication.
                </p>
            </div>

            {/*bloc données personnelles*/}
            <div className="mentions-legales-editeur">
                <h2>Données personnelles</h2>
                <p>
                    Les informations recueillies via le formulaire de contact sont utilisées exclusivement pour
                    répondre à votre demande et établir un devis. Ces données sont traitées conformément à
                    notre politique de confidentialité, que vous pouvez retrouver sur la page Contact du site
                    internet.
                </p>
                <p>
                    Conformément à la réglementation applicable (notamment le Règlement Général sur la
                    Protection des Données - RGPD), vous disposez d’un droit d’accès, de rectification, de
                    suppression et d’opposition concernant vos données personnelles. Pour exercer ces droits,
                    veuillez nous contacter à l’adresse suivante : info@grendy-communication.fr
                </p>
            </div>

            {/*bloc cookies*/}
            <div className="mentions-legales-editeur">
                <h2>Cookies</h2>
                <p>Ce site n'utilise pas de cookies</p>
            </div>

            {/*bloc responsabilité*/}
            <div className="mentions-legales-editeur">
                <h2>Responsabilité</h2>
                <p>Grendy Communication s’efforce d’assurer l’exactitude et la mise à jour des informations
                    diffusées sur ce site. Cependant, Grendy Communication ne saurait être tenu responsable
                    d’éventuelles erreurs ou omissions dans le contenu du site, ni dans l’utilisation qui pourrait
                    être faite de ces informations.</p>
            </div>

            {/*bloc contact*/}
            <div className="mentions-legales-editeur">
                <h2>Contact</h2>
                <p>Pour toute question ou information, vous pouvez nous contacter à l’adresse suivante :</p>
                <p style={{marginBottom:'0'}}>Grendy Communication</p>
                <p>8 rue du Vichoux F-54920 Morfontaine</p>
                <p><a href="tel: +33355054911">+33 (0) 3 55 05 49 11</a></p>
                <p><a href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
            </div>

        </div>
    )
}
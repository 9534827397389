import '../../Styles/otherServicesCard.scss';
//icons imports
import email from'../../Assets/icons/email.png';
import domain from'../../Assets/icons/domain.png';
import hosting from'../../Assets/icons/hosting.png';
import seo from '../../Assets/icons/seo.png';
import ads from '../../Assets/icons/ads.png';
import analytics from '../../Assets/icons/analytics.png';
import OtherCard from "../../Components/OtherCard/otherCard";

export default function OtherServicesCar(){
    const services=[
        {title:"Adresses mail", desc:"Création d’adresses mail professionnelles et personnalisées", img:email},
        {title:"Noms de domaine", desc:"Création et gestion de vos noms de domaine", img:domain},
        {title:"Hébergement", desc:"Hébergement annuel de votre site, avec gestion des certificats SSL", img:hosting},
        {title:"Audit SEO", desc:"Audit et optimisation de votre référencement naturel, pour une meilleure" +
                " visibilité", img:seo},
        {title:"Campagne Google Ads", desc:"Création et gestion de votre campagne publicitaire Google Ads, afn" +
                " d’augmenter considérablement le traffic vers votre site internet", img:ads},
        {title:"Rapports Google Analytics", desc:"Edition mensuelle de rapports Google Analytics, afin de mieux" +
                " comprendre le comportement des utilisateurs de votre site, et d’adapter ainsi votre stratégie" +
                " marketing", img:analytics}
    ]
    return(
        <div>
            <div className="cards">
                {services.map((service, index) => {
                    return (
                        <OtherCard
                            key={index}
                            img={service.img}
                            title={service.title}
                            desc={service.desc}
                        />
                    )
                })}
            </div>
        </div>
    )
}
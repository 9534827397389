import '../../Styles/customerCard.scss';
import {Link} from "react-router-dom";


export default function CustomerCard({img, title, desc, elements}){
    return(
        <Link to="/Realisation" className="customerCard">
            <img src={img} alt="photo représentant le client"/>
            <div className="customerCardDesc">
                <h2>{title}</h2>
                <p>{desc}</p>
                {elements && elements.length >0 && (
                    <ul>
                        {elements.map((element, index)=> {
                            return <li key={index}>{element}</li>
                        })}
                    </ul>
                    )}
            </div>

        </Link>
    )
}
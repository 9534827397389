

export default function CGV(){
    return (
        <div>
            <div className="mentions-legales">
                <h1>Conditions Générales de Vente (CGV)</h1>

                <div className="mentions-legales-editeur">
                    <p>Dernière mise à jour : le 02 décembre 2024</p>
                    <p>Les présentes Conditions Générales de Vente (CGV) définissent les droits et obligations de [Nom
                        de votre agence] et de ses clients dans le cadre de la vente de prestations de services,
                        incluant la création de sites internet, la communication, et l’infographie print et
                        digitale.</p>

                </div>

                {/*bloc identification entreprise*/}
                <div className="mentions-legales-editeur">
                    <h2> Identification de l’entreprise :</h2>
                    <div>
                        <p><strong>Nom de l'agence :</strong> Michel Lallemand Sandy (Entreprise Individuelle), sous le
                            nom
                            commercial Grendy Communication.</p>
                        <p><strong>Forme juridique :</strong> Auto-entreprise (Entreprise Individuelle)</p>
                        <p><strong>Capital social :</strong> 1€</p>
                        <p><strong>Siège social :</strong> 8 rue du Vichoux F-54920 Morfontaine</p>
                        <p><strong>Numéro de Siret :</strong> 947 537 023 00014</p>
                        <p><strong>Téléphone :</strong> +33 (0) 3 55 05 49 11</p>
                        <p><strong>Email :</strong><a
                            href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
                        <p><strong>Directeur de la publication :</strong> Lallemand Sandy</p>
                    </div>
                </div>

                {/*bloc acceptation des CGV*/}
                <div className="mentions-legales-editeur">
                    <h2>Acceptation des CGV</h2>
                    <p>
                        Toute commande passée auprès de [Nom de votre agence] implique l’acceptation pleine et entière
                        des présentes CGV, sauf conditions particulières expressément acceptées par écrit entre les
                        parties.

                        Ces CGV sont disponibles sur le site internet <a href="/">www.grendy-communication.fr</a> et
                        sont transmises au client sur demande.
                    </p>

                </div>

                {/*bloc perstations proposées*/}
                <div className="mentions-legales-editeur">
                    <h2>Prestations proposées</h2>
                    <p>Grendy Communication propose les services suivants :</p>
                    <p>- Création de sites internet personnalisés</p>
                    <p>- Conception graphique print et digitale (logos, flyers, brochures, etc.)</p>
                    <p>- Conseils en communication digitale et print</p>
                    <p>Le détail des prestations est précisé dans le devis transmis au client avant toute validation de
                        commande.</p>
                </div>

                {/*bloc devis et commande*/}
                <div className="mentions-legales-editeur">
                    <h2>Devis et commande</h2>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>1. Devis</p>
                    <p>Un devis détaillé est systématiquement établi pour toute prestation. Ce devis reste valable pour
                        une durée de [60 jours] à compter de sa date d’émission.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>2. Commande</p>
                    <p>La commande est validée après acceptation écrite du devis par le client (signature ou email de
                        confirmation) et, si prévu, paiement d’un acompte.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>3. Modification de la commande</p>
                    <p>Toute modification demandée par le client après validation du devis pourra entraîner une révision
                        des délais et/ou des tarifs.</p>
                </div>

                {/*bloc tarifs et paiement*/}
                <div className="mentions-legales-editeur">
                    <h2>Tarifs et modalités de paiement</h2>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>1. Tarifs</p>
                    <p>Les prix sont exprimés en euros (€) et s’entendent hors taxes (HT), sauf mention contraire. La
                        TVA est appliquée au taux en vigueur, si applicable.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>2. Paiement</p>
                    <p>Le paiement peut s’effectuer selon les modalités suivantes :</p>
                    <p>- Accompte de [30%] à la commande</p>
                    <p>- Solde à la livraison de la prestation</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>3. Retard de paiement</p>
                    <p>En cas de retard de paiement, des pénalités égales à [taux d’intérêt applicable] seront
                        appliquées, ainsi qu’une indemnité forfaitaire pour frais de recouvrement de 40 €, conformément
                        aux articles L.441-10 et suivants du Code de commerce.</p>
                </div>

                {/*bloc resiliation*/}
                <div className="mentions-legales-editeur">
                    <h2>Délais de réalisation</h2>
                    <p>Grendy Communication s’engage à respecter les délais mentionnés dans le devis. Ces délais sont
                        donnés à titre indicatif et peuvent être ajustés en cas de :</p>
                    <p> - Retard dans la fourniture des éléments nécessaires par le client</p>
                    <p> - Modification de la commande en cours de réalisation</p>
                    <p>Tout retard imputable au client entraînera un ajustement des délais initiaux.</p>
                </div>

                {/*bloc obligations du client*/}
                <div className="mentions-legales-editeur">
                    <h2>Obligations du client</h2>
                    <p>Le client s’engage à :</p>
                    <p> - Fournir des informations complètes, exactes et dans les délais convenus (textes, images,
                        contenus, etc.) nécessaires à la réalisation de la prestation.</p>
                    <p> - Vérifier et valider les livrables (ex. : maquettes, prototypes).</p>
                    <p>Grendy Communication ne pourra être tenu responsable des retards ou erreurs résultant d’un
                        manquement à ces obligations.</p>
                </div>

                {/*bloc propriete intellectuelle*/}
                <div className="mentions-legales-editeur">
                    <h2>Propriété intellectuelle</h2>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>1. Livrables</p>
                    <p>Les livrables (sites, graphiques, etc.) restent la propriété de Grendy Communication jusqu’au paiement intégral de la prestation.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>2. Utilisation des créations</p>
                    <p>Les créations fournies par Grendy Communication sont destinées à un usage défini dans le devis. Toute utilisation non autorisée nécessite une autorisation préalable et écrite.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>3. Cession des droits</p>
                    <p>La cession des droits de propriété intellectuelle peut être incluse dans la prestation ou faire l’objet d’une facturation supplémentaire.</p>
                </div>

                {/*bloc responsabilite*/}
                <div className="mentions-legales-editeur">
                    <h2>Responsabilité</h2>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>1. Garanties</p>
                    <p>Grendy Communication garantit la conformité des prestations aux spécifications définies dans le devis.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>2. Limitation de responsabilité</p>
                    <p>La responsabilité de Grendy Communication est limitée aux dommages directs et ne saurait excéder le montant total de la prestation concernée. Grendy Communication ne pourra être tenu responsable des pertes de données, interruptions de service, ou préjudices indirects.</p>
                </div>

                {/*bloc resiliation*/}
                <div className="mentions-legales-editeur">
                    <h2>Résiliation</h2>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>1. À l’initiative du client</p>
                    <p>Toute résiliation doit être notifiée par écrit. En cas de résiliation après validation de la commande, l’acompte versé restera acquis à Grendy Communication.</p>
                    <p style={{
                        color: 'white',
                        fontWeight: 'bold'
                    }}>2. À l’initiative de Grendy Communication</p>
                    <p>Grendy Communication se réserve le droit de résilier la commande en cas de non-respect des obligations contractuelles par le client.</p>
                </div>

                {/*bloc confidentialite*/}
                <div className="mentions-legales-editeur">
                    <h2>Confidentialité</h2>
                    <p>Grendy Communication et le client s’engagent à préserver la confidentialité des informations échangées dans le cadre de la prestation.</p>
                </div>

                {/*bloc litiges*/}
                <div className="mentions-legales-editeur">
                    <h2>Litiges et droit applicable</h2>
                    <p>Les présentes CGV sont soumises au droit français.</p>
                    <p>En cas de litige, une solution amiable sera recherchée avant toute action en justice. Si aucun accord n’est trouvé, le litige sera soumis aux tribunaux compétents du ressort du siège social de Grendy Communication.</p>
                </div>

                {/*bloc contact*/}
                <div className="mentions-legales-editeur">
                    <h2>Contact</h2>
                    <p>Pour toute question ou information, vous pouvez nous contacter à l’adresse suivante :</p>
                    <p style={{marginBottom:'0'}}>Grendy Communication</p>
                    <p>8 rue du Vichoux F-54920 Morfontaine</p>
                    <p><a href="tel: +33355054911">+33 (0) 3 55 05 49 11</a></p>
                    <p><a href="mailto:info@grendy-communication.fr"> info@grendy-communication.fr</a></p>
                </div>


            </div>
        </div>
    )
}
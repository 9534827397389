import '../../Styles/grefServiceCard.scss';

export default function GrafServiceCard({img, title, desc}){
    return(
        <div className="infografService">
            <img src={img} alt="infography service icon"/>
            <h2>{title}</h2>
            <div className="infografServiceDesc">
                {desc.map((description, index) => (
                    <p key={index}>{description}</p>
                ))}
            </div>
        </div>
    )
}